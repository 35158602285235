// Webpack build entry point
import moment from 'moment-timezone'
import 'moment-duration-format'
import {Router, Vue} from './services/Vue'
import apiServiceFactory from './services/APIService'
import ModalDialog from './components/common/ModalDialog.vue1'
import {imageAssetUrl} from '@/helpers/Assets'
import EventData from './EventData'
import RoleTeller from './RoleTeller'
import Cache from './Cache'
import GETCache from '@/classes/GETCache'
import jquery from 'jquery'
import ttd from './TicketTemplateData'
import {isAuthenticated} from '@/helpers/access'
import {getInitialPromise} from '@/helpers/api'
import {defer} from '@/helpers/Promise'
import Pendo from '@/classes/Pendo'
import {isEmpty, isEmptyObject, isSame, objDiff} from '@/helpers/comparison'
import {getFirst, getHome} from '@/helpers/route'
import {logInTrackJS} from '@/helpers/errors'
import {arrayRemove, capitalise, copy, dClone, jsonify, lj, mapArray, sortObject} from '@/helpers/format'
import 'offline-js'
import 'timepicker'
import {useAuth} from '@okta/okta-vue'
import sanitizeHtml from 'sanitize-html'
import {sendMessageToCMSNext} from '@/helpers/CMSNext'
import {globalHandler} from '@/api/error/globalHandler'
import modalSingleton from '@/classes/ModalSingleton'
import toast from '@/classes/ToastSingleton'

sanitizeHtml.defaults.allowedAttributes.span = ['class', 'data-*']
window.sanitizeHtml = sanitizeHtml // for the patched Vue1 template parser to use
window.$ = window.jQuery = jquery
window.jQueryModulesPromise = import('./initial-modules-that-require-global-jquery')

// Backward compat references:
window.isEmpty = isEmpty
window.isEmptyObject = isEmptyObject
window.copy = copy

// Unlike "moment.diff", "add" and "subtract" methods do not take DST into account.
// Here are DST-safe versions of those methods.
// Rather than directly manipulating DST offsets, they simply cross-check the results
// attempting to get the original duration by diffing the resulting moment instance with the start moment.
// If the cross-checked duration differs from the supplied one, the result is adjusted by the difference
// between the two durations:
moment.fn.dstSafeAddSubtract = function (duration, trueIfAdd) {
  let candidate = this.clone()[trueIfAdd ? 'add' : 'subtract'](duration)
  // Cross-check:
  const checkDurationMins = moment.duration(this.clone().diff(candidate)).asMinutes()
  const originalMins = Math.abs(duration.asMinutes())
  if (originalMins !== Math.abs(checkDurationMins)) {
    // Cross-check failed apparently because of crossing DST boundary. Adjust the result:
    candidate[checkDurationMins > 0 ? 'subtract' : 'add'](originalMins - Math.abs(checkDurationMins), 'minutes')
  }
  return candidate
}
moment.fn.dstSafeAdd = function (duration) {
  return this.dstSafeAddSubtract(duration, true)
}
moment.fn.dstSafeSubtract = function (duration) {
  return this.dstSafeAddSubtract(duration)
}

window.escapeHTML = htmlStr => htmlStr.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/"/g, '&quot;').replace(/'/g, '&#39;')

window.isTouchDevice = 'ontouchstart' in window || navigator.MaxTouchPoints > 0

window.propSort = (a, b, prop) => {
  if (a[prop] === undefined || b[prop] === undefined || a[prop] === b[prop]) {
    return 0
  }
  return a[prop] > b[prop] ? 1 : -1
}

window.rankSort = (a, b) => window.propSort(a, b, '_rank')

window.array2map = a => {
  const map = new Map()
  a.forEach(el => map.set(el[0], {title: el[1]}))
  return map
}

window.emptyArray = a => a.splice(0, a.length)

window.invertObject = o => {
  const inv = {}
  Object.keys(o).forEach(k => {
    inv[o[k]] = k
  })
  return inv
}

window.chunkArray = (a, maxLength) => {
  const chunks = []
  while (a.length > maxLength) {
    chunks.push(a.splice(0, maxLength))
  }
  chunks.push(a)
  return chunks
}

window.getTotalFromTicketArray = (ts, key) => {
  key = key || 'adjusted_value'
  return ts.reduce((sum, ticket) => sum + parseFloat(ticket[key]), 0)
}
window.getFirstDataEntry = (apiResult, resourceName) => (apiResult && apiResult[resourceName] && apiResult[resourceName]._data && apiResult[resourceName]._data.length ? apiResult[resourceName]._data[0] : false)
// Add the ability to find index by key/elementId to Map/Set
window.mapIndexOf = (map, k) => {
  if (!map.has(k)) {
    return -1
  }
  if (!map.indexMap) {
    map.indexMap = new Map()
    let i = 0
    map.forEach((v, _k) => {
      map.indexMap.set(_k, i)
      i += 1
    })
  }
  return map.indexMap.get(k)
}
window.setIndexOfId = (set, id) => {
  if (!set.indexMap) {
    set.indexMap = new Map()
    let i = 0
    set.forEach(e => {
      set.indexMap.set(e.id, i)
      i += 1
    })
  }
  return set.indexMap.has(id) ? set.indexMap.get(id) : -1
}
window.toHuman = (str, useSentenceCase = false) => {
  if (typeof str === 'string' && str.length) {
    str = str.split('_')
    str.forEach((l, i) => {
      if (!useSentenceCase) {
        str[i] = capitalise(l)
      } else if (i === 0) {
        str[i] = capitalise(l)
      }
    })
    str = str.join(' ')
  }
  return str
}
window.humanSeconds = seconds => {
  if (seconds === 3600) {
    // Special case which I'm not sure deserves a better accommodation than hardcoding:
    // https://tixtrackteam.slack.com/archives/CS1AZ0ZBK/p1657821145174119?thread_ts=1657801282.398479&cid=CS1AZ0ZBK
    return '60 minutes'
  }
  const levels = [
    [parseInt(Math.floor(seconds / 31536000), 10), 'years'],
    [parseInt(Math.floor((seconds % 31536000) / 86400), 10), 'days'],
    [parseInt(Math.floor(((seconds % 31536000) % 86400) / 3600), 10), 'hours'],
    [parseInt(Math.floor((((seconds % 31536000) % 86400) % 3600) / 60), 10), 'minutes'],
    [parseInt((((seconds % 31536000) % 86400) % 3600) % 60, 10), 'seconds']
  ]
  let returntext = ''
  let levelsIncluded = 0
  for (let i = 0, max = levels.length; i < max; i++) {
    if (levels[i][0] === 0) continue
    returntext += ' ' + levels[i][0] + ' ' + (levels[i][0] === 1 ? levels[i][1].substr(0, levels[i][1].length - 1) : levels[i][1]) // cut the "s" suffix where the number is 1
    levelsIncluded++
    if (levelsIncluded === 2) {
      break
    }
  }
  return returntext.trim()
}

window.intersectSets = (...sets) => {
  sets = Array.from(sets)
  let intersection = sets.shift()
  sets.forEach(set => {
    intersection = new Set([...intersection].filter(x => set.has(x)))
  })
  return intersection
}
window.subtractSets = (set1, set2) => new Set([...set1].filter(x => !set2.has(x)))
window.areEqualSets = (a, b) => a.size === b.size && [...a].every(value => b.has(value))

window.sortMap = (map, sorter) => new Map([...map.entries()].sort(sorter))

window.swallow = fn => {
  try {
    fn()
  } catch (e) {} // eslint-disable-line no-empty
}

window.moneyNumberString = inp => {
  if (isEmpty(inp)) {
    return '0.00'
  }
  return String(parseFloat(inp).toFixed(2))
}

window.cache = new Cache()

const processRouteTransition = transition => {
  const route = transition.to
  if (!route.matched) {
    // The requested route does not exist.
    let froute
    if (theApp.isAuthenticated) {
      froute = getFirst()
    }
    // Redirect to the first route that the user (if logged in) has access to:
    if (froute) {
      transition.redirect(froute)
    } else {
      theApp.isAuthorised = false
    }
  } else {
    if (location.pathname === '/' && !theApp.isRouteAllowed(route)) {
      // The root has been requested but the user has no access to the route mapped to it.
      // This happens, for example, for the developer role (if no other roles are assigned)
      // as it does not have access to Sellers and this is the route mapped to '/'.
      // What to do? Attempt to find another route available, and switch to it:
      const tryAnother = getHome()
      if (tryAnother) {
        transition.redirect(tryAnother)
        return
      }
    }
    // If the requested route is only available to certain roles and the user has none of them,
    // set the isAuthorised flag to FALSE so that a special view is rendered instead of the normal router view:
    theApp.setAuthorisation(route)
    // Go ahead. Use $nextTick to ensure that any templates that rely on the isAuthorised variable see its updated value upon rendering:
    theApp.$nextTick(() => transition.next())
  }
}

const vue1AppSellerDeferred = defer()
window.vue1AppSellerPromise = vue1AppSellerDeferred.promise

Router.beforeEach(transition => {
  // Detect if seller scope has changed.
  // If so, flush seller-scoped data.
  // If the user has access to the specified seller, order the reload of various seller-scoped cached data.
  const route = transition.to
  const app = route.router.app
  app.isNotFound = route.name === 'notfound'
  const sellersRoute = {
    name: 'sellers',
    query: {
      seller: null
    }
  }

  // Routes that need to show a confirmation modal before navigation can use 'preventNavigation'
  // If `preventNavigation` is present, assume navigation will be aborted, skip the seller checks below and continue
  if (transition.from.meta && transition.from.meta.preventNavigation) {
    transition.next()
    return
  }

  getInitialPromise().then(() => {
    if (route.matched && isAuthenticated()) {
      if (app.sellers.size) {
        const sellerInQuery = app.getSellerInQuery(route)
        if (route.seller !== false && !sellerInQuery) {
          // This route can only be accessed with seller scope. Malformed URL.
          transition.redirect(sellersRoute)
          return
        }
        const isSellerChanged = Boolean((sellerInQuery && (!app.seller || app.seller.id !== sellerInQuery)) || (!sellerInQuery && app.seller))
        EventData.getInstance(isSellerChanged)
        if (isSellerChanged) {
          GETCache.bustSeller()
          delete window.membershipRules
          delete window.sellerFeesPromise
          app.applySeller(sellerInQuery ? app.sellers.get(sellerInQuery) || null : null)
        }
      }
    }
    processRouteTransition(transition)
  })
})

Router.afterEach(transition => {
  const route = transition.to
  route.router.app.routeName = route.name ? `route-${route.name}` : ''
})

// This works when an API call fails which is not handled anywhere else.
// This is the normal way of handling accidental 401/403 errors (such as when an action button is presented by the UI which the user does not have permission to use).
// See https://tixtrack.atlassian.net/browse/TIC-2302.
// Note that TrackJS handles these rejections independently, and it may attempt to do so before or after our handling here.
window.addEventListener('unhandledrejection', e => {
  if (globalHandler(e.reason)) {
    // Prevent this error from polluting the console / raising alarms in the default way; we'll do it our way instead.
    // See https://developer.mozilla.org/en-US/docs/Web/API/Window/unhandledrejection_event#preventing_default_handling
    // Note that this does not work in Firefox - the error still makes its way to the console.
    // See https://stackoverflow.com/questions/62100577/event-preventdefault-does-not-seem-to-work-async-context
    e.preventDefault()
  }
})

const ifDirective = Vue.directive('if')
const allowDirective = Object.assign({}, ifDirective)
/* eslint-disable */
// This needs NOT to be an arrow function because the "this" keyword inside it
// needs to point to the execution scope.
allowDirective.update = function (value) {
  if (this.invalid || !value) return
  if (value.includes(',')) {
    value = value.split(',')
  } else {
    value = [value]
  }
  let show = false
  let hasNonReadOnly = false
  let i = 0
  for (; i < value.length; i++) {
    if (theApp.roleTeller.has(value[i])) {
      show = true
      if (!value[i].includes('read-only')) {
        hasNonReadOnly = true
        break
      }
    }
  }

  if (show && !hasNonReadOnly && $(this.el).data('readonly') === 'disable') {
    // Write permission is required which the user doesn't have (they're readonly)
    // and the instruction is to disable the UI in this case
    show = null
  }
  if (show === false) {
    this.remove()
  } else {
    if (!this.frag) {
      if (show === null) {
        $(this.el).addClass('form-disabled')
      }
      this.insert()
    }
  }
}
window.jsonify = jsonify
window.lj = lj
window.cp = (base, source, keys) => {
  const data = window.dClone(base)
  keys.forEach(k => {
    if (source[k] !== undefined) {
      data[k] = source[k]
    }
  })
  return data
}
const mindBoolean = (value, expectBool) => (expectBool ? !window.isFalsey(value) : value)
window.config = (path, context, def) => {
  context = context || window.CONFIG || {}
  if (!Array.isArray(path)) {
    path = path.split('.')
  }
  const namespace = path.shift()
  // Cast any return value to boolean if the provided default is strictly boolean
  const expectBool = def === true || def === false
  if (context[namespace] === undefined) {
    if (namespace === 'config' && path[0] === 'custom_identity_fields') {
      logInTrackJS('config.custom_identity_fields requested when it is not available, returning the default', def)
    }
    return mindBoolean(def === undefined ? null : def, expectBool)
  }
  return mindBoolean(path.length ? window.config(path, context[namespace], def) : context[namespace], expectBool)
}
window.giftCardsOn = () => {
  return !!window.config('ledgers.config.default_type')
}

// Restricts input for the given textbox to the given inputFilter function.
// https://stackoverflow.com/questions/469357/html-text-input-allow-only-numeric-input?rq=1
window.setInputFilter = (textbox, inputFilter) => {
  ;['input', 'keydown', 'keyup', 'mousedown', 'mouseup', 'select', 'contextmenu', 'drop'].forEach(function (event) {
    textbox.addEventListener(event, function () {
      if (inputFilter(this.value)) {
        this.oldValue = this.value
        this.oldSelectionStart = this.selectionStart
        this.oldSelectionEnd = this.selectionEnd
      } else if (this.hasOwnProperty('oldValue')) {
        this.value = this.oldValue
        this.setSelectionRange(this.oldSelectionStart, this.oldSelectionEnd)
      } else {
        this.value = ''
      }
    })
  })
}

window.setInputNumberFilter = el => window.setInputFilter(el, val => /^\d*$/.test(val))

/* eslint-enable */
Vue.directive('allow', allowDirective)

window.state = new Map()

window.scrollInEl = (vue, el) => {
  if (!el.length) {
    return
  }
  const scroll = () => {
    $('html, body').animate(
      {
        scrollTop: el.offset().top
      },
      1000
    )
  }
  if (vue) {
    vue.$nextTick(scroll)
  } else {
    scroll()
  }
}

window.scrollIn = (vue, elId) => {
  window.scrollInEl(vue, $(`#${elId}`))
}

window.scrollTop = vue => {
  window.scrollInEl(vue, $('html'))
}

window.isFalsey = o => {
  if (isEmpty(o)) {
    return true
  }
  if (typeof o === 'string') {
    o = o.toLowerCase()
    return ['0', 'false', 'no', 'off'].includes(o)
  }
  return !o
}

window.dClone = dClone

// shallow clone
window.sClone = o => Object.assign({}, o)

window.arrayRemoveAt = (a, index) => {
  a.splice(index, 1)
}
window.arrayRemove = arrayRemove

window.arrayDiff = (b, a) => b.filter(i => a.indexOf(i) === -1)

window.isSame = isSame

window.objDiff = objDiff

window.objPropsSame = (a, b) => {
  const keys = Object.keys(a)
  let i = 0
  for (; i < keys.length; i++) {
    if (b[keys[i]] !== undefined && a[keys[i]] !== b[keys[i]]) {
      return false
    }
  }
  return true
}

window.isArrayOfObjSame = (a, b, sortBy) => {
  if (!Array.isArray(a) || !Array.isArray(b) || a.length !== b.length) {
    return false
  }
  if (sortBy) {
    const sorter = (_a, _b) => window.propSort(_a, _b, sortBy)
    a = Array.from(a).sort(sorter)
    b = Array.from(b).sort(sorter)
  }
  let i = 0
  for (; i < a.length; i++) {
    if (!b[i] || !window.objPropsSame(a[i], b[i])) {
      return false
    }
  }
  return true
}

window.isObject = foo => foo === Object(foo) && !Array.isArray(foo)

window.sortObject = sortObject

window.treatApiPendingUI = (on, ui) => {
  const action = on ? 'addClass' : 'removeClass'
  let spinnerOnUi = false
  if (ui) {
    if (ui instanceof HTMLElement) {
      ui = $(ui)
      let el
      let s
      ;['ui-disabled', 'spinner', 'hidden'].forEach(cls => {
        s = `[data-submit-${cls}]`
        if (ui.is(s)) {
          el = ui
        } else {
          el = ui.find(s)
        }
        if (el.length) {
          el[action](cls)
          if (cls === 'spinner') {
            spinnerOnUi = true
          }
        }
      })
    }
  }
  if (!spinnerOnUi) {
    $('body')[action]('spinner')
  }
}

window.ensureVenues = () => {
  if (!window.venuesPromise) {
    window.venuesPromise = window.APIService.get(
      'venue',
      {
        _embed: 'meta,venue_map',
        'meta.metakey': 'image',
        _limit: 200,
        _sort: 'name'
      },
      {noMetaMerge: true}
    ).then(response => {
      const images = mapArray(response.meta._data, 'resource_id')
      const maps = mapArray(response.venue_map._data, 'venue_id', false, true)
      const venues = response.venue._data
      venues.forEach(v => {
        const meta = images.get(v.id)
        v.image = meta ? meta.value : null
        v.maps = maps.get(v.id) || []
        v.mapsMap = mapArray(v.maps, 'map_asset')
        v.mapById = mapArray(v.maps)
      })
      window.venues = venues
      window.venueMap = mapArray(venues)
      return venues
    })
  }
  return window.venuesPromise
}

window.getMembershipRulesPromise = ui => {
  if (window.membershipRulesPromise) {
    return window.membershipRulesPromise
  }
  if (window.membershipRules) {
    return Promise.resolve().then(() => window.membershipRules)
  }
  window.membershipRulesPromise = window.APIService.get(
    'membership_rules',
    {
      _limit: 1000
    },
    {ui}
  ).then(r => {
    window.membershipRules = mapArray(r.membership_rules._data, 'ticket_group_id', false, true)
    delete window.membershipRulesPromise
    return window.membershipRules
  })
  return window.membershipRulesPromise
}

window.groupBy = (src, prop) => {
  const index = new Map()
  src.forEach(el => {
    const p = typeof prop === 'function' ? prop(el) : el[prop]
    if (p) {
      if (!index.has(p)) {
        index.set(p, new Set())
      }
      index.get(p).add(el)
    }
  })
  return index
}

window.sellerFees = () => {
  if (!window.sellerFeesPromise) {
    /* eslint-disable */
    window.sellerFeesPromise = window.APIService.get('fee_set', {
      _sort: 'name',
      _limit: 500
    }).then(r => {
      const feeSets = r.fee_set._data
      const feeSetOptions = []
      feeSetOptions.push(['', {title: '[Default]'}])
      feeSets.forEach(f => {
        feeSetOptions.push([f.id, {title: f.name}])
      })
      return {
        feeSets,
        feeSetOptions: new Map(feeSetOptions)
      }
    })
    /* eslint-enable */
  }
  return window.sellerFeesPromise
}

const TixApp = Vue.extend({
  components: {
    'modal-dialog': ModalDialog
  },

  data() {
    return {
      portal: null,
      identity: null, // null for not yet known / not yet loaded, false for logged out, Identity for logged in and fully loaded
      seller: null,
      sellers: new Map(),
      roleTeller: new RoleTeller(),
      userMenuVisible: false,
      widgetClass: 'HeaderProfileDropdown__menu-content',
      toggleClass: 'HeaderProfileDropdown__menu-toggle',
      preventUnloadMessage: null,
      // Indicates whether the current user is allowed to see the requested route.
      // If not, a special view will be rendered instead of the router view.
      isAuthorised: null,
      isNotFound: false,
      routeName: ''
    }
  },

  computed: {
    isAuthenticated() {
      return !!this.identity
    },
    homeRoute() {
      return getHome()
    },
    // The /settings/* routes have different access requirements.
    // The first available one depends on the user roles.
    firstSettingsRoute() {
      let s = routeMap.get('portal-settings-root')
      if (this.roleTeller.has(s.roles)) {
        s = s.subRoutes
        for (const k in s) {
          if (s[k].menu === false) {
            continue
          }
          if (!s[k].roles || this.roleTeller.has(s[k].roles)) {
            return s[k].name
          }
        }
      }
      return false
    },
    routeViewCls() {
      const cls = ['router-view']
      if (!this.isNotFound) {
        cls.push(this.$route.name)
      }
      return cls.join(' ')
    },
    subheaderCls() {
      return `row subheader-${this.$route.name}`
    },
    headerGlobalNavCls() {
      return `HeaderGlobalNav header-${this.$route.name}`
    },
    sellerImage() {
      if (!this.seller || !this.seller._meta) {
        return ''
      }
      return this.seller._meta.image || ''
    },
    showKibana() {
      return this.roleTeller.hasPortalRole('reporting-author')
    },
    giftCardsOn() {
      return window.giftCardsOn()
    },
    sellerImageStyle() {
      return this.sellerImage ? `--bg-image: url('${imageAssetUrl(this.sellerImage, null, 42)}')` : 'display: none'
    }
  },

  watch: {
    isAuthorised(v) {
      this.$emit('authorisationchange', v)
    },
    isNotFound(v) {
      if (v) {
        this.$emit('notfound')
      }
    }
  },

  created() {
    window.APIService = apiServiceFactory(this)
    window.theApp = this
    window.logOut = () => this.logOut()
    // Poll the presence of the auth cookie, drop to the Log In screen if it is missing:
    this.cookiePollInterval = setInterval(() => {
      if (this.isAuthenticated && !isAuthenticated()) {
        this.onLogOut()
      }
    }, 1000)
  },

  // Likely not needed, but just in case
  beforeUnmount() {
    clearInterval(this.cookiePollInterval)
  },

  compiled() {
    window.addEventListener(
      'beforeunload',
      e => {
        if (!this.preventUnloadMessage) {
          return null
        }
        e.returnValue = this.preventUnloadMessage
        return this.preventUnloadMessage
      },
      false
    )
    window.addEventListener('message', e => {
      if (e.data.logout) {
        window.logOut()
        sendMessageToCMSNext({loggedIn: false})
      }
    })
  },

  events: {
    preventUnload(message) {
      this.preventUnloadMessage = message
    },
    login(ui) {
      localStorage.clear()
      this.closeUserMenu()
      GETCache.bustAll()
      getInitialPromise(ui, true).then(() => {
        EventData.getInstance(true)
        const sellerInQuery = this.getSellerInQuery()
        const seller = sellerInQuery ? this.sellers.get(sellerInQuery) : null
        if (seller) {
          this.applySeller(seller)
        }
        let redirected = false
        if (!this.isRouteAllowed()) {
          const tryAnother = getHome()
          if (tryAnother) {
            Router.go({name: tryAnother})
            redirected = true
          }
        }
        if (!redirected) {
          this.setAuthorisation()
        }
        this.$emit('authenticationchange', true)
      })
    }
  },

  methods: {
    isRouteAllowed(route) {
      route = route || this.$route
      return (!route.roles || this.roleTeller.has(route.roles)) && (!route.envs || route.envs.includes(MODE))
    },

    setAuthorisation(route) {
      this.isAuthorised = this.isRouteAllowed(route)
    },

    getFirstSellerLevelRoute(sellerID) {
      const accessibleSellerRoutes = Array.from(routeMap.values()).filter(r => !!r.inSellerMenu && (!r.envs || r.envs.includes(MODE)) && (!r.roles || this.roleTeller.has(r.roles, sellerID)))
      // Prioritise the Orders route if it is accessible
      const foundOrdersRoute = accessibleSellerRoutes.find(r => r.name === 'orders')
      if (foundOrdersRoute) {
        return foundOrdersRoute
      }
      if (!accessibleSellerRoutes.length) {
        // No routes are accessible, https://my.trackjs.com/details/7c4e00c6cda94411bfe81f0a00b29206
        logInTrackJS(`User with no seller-level routes accessible for seller ${sellerID}`, this.getIdentitySnapshot())
        return this.$route
      }
      // Otherwise return the first one in the menu
      return accessibleSellerRoutes.sort((a, b) => (a.inSellerMenu > b.inSellerMenu ? 1 : -1)).shift()
    },

    getSellerSlugUrlPart() {
      return this.seller && this.sellers.size > 1 ? `${this.seller.slug}/` : ''
    },

    getSellerInQuery(route) {
      if (!route) {
        route = this.$route
      }
      return route.query && route.query.seller ? route.query.seller : null
    },

    // If there is seller ID in the query, returns TRUE/FALSE indicating whether the user has access to that seller.
    // If there is no seller ID in the query, returns NULL.
    userHasAccessToTheSeller() {
      const sellerID = this.getSellerInQuery()
      if (sellerID === null) {
        return null
      }
      return this.sellers.has(sellerID)
    },

    applySeller(seller) {
      this.seller = seller
      ttd.flush()
      if (seller) {
        this.$broadcast('sellerchange')
        vue1AppSellerDeferred.resolve()
      }
    },

    sellerLink(route) {
      return {
        name: route,
        query: {
          seller: this.seller.id
        }
      }
    },

    applyIdentity(identity) {
      this.roleTeller = new RoleTeller()
      if (identity.roles) {
        identity.roles.forEach(role => this.roleTeller.add(role, role.seller_id))
      }
      this.identity = identity
      Pendo.onIdentityChange()
    },

    // For debugging
    getIdentitySnapshot() {
      if (!this.identity) {
        return null
      }
      return {
        identity: this.identity.id,
        roles: this.roleTeller.getRoleSnapshot()
      }
    },

    logOut() {
      window.APIService.post('logout').then(() => {
        this.onLogOut()
        sessionStorage.removeItem('loginMode')
      })

      const sso = window.TIX.sso?.okta_staff_sso
      if (sso) {
        const auth = useAuth()
        if (auth) {
          auth.clearStorage()
        }
      }
    },

    onLogOut() {
      this.identity = false
      this.sellers = new Map()
      // Use document DOM events as a global event bus.
      document.dispatchEvent(new CustomEvent('tix authentication', {authenticated: false}))
      // TODO Should GETCache bind to the `tix authentication` event instead of having a dependency from main?
      GETCache.bustAll()
      // TODO Use the `tix authentication` event instead?
      this.$emit('authenticationchange', false)
      modalSingleton.sr.isOn = false
      Pendo.onIdentityChange()
      return null
    },

    toggleUserMenu() {
      this.userMenuVisible = !this.userMenuVisible
    },

    closeUserMenu(e) {
      toast.closeIfOldEnough()
      if (!e || e.target.id !== 'toggleUserMenu') {
        this.userMenuVisible = false
      }
    }
  }
})

window.Router1 = Router
Router.start(TixApp, 'html')
